export function readableDateTime(timestamp: number): string {
    let obj: Date = new Date(timestamp)
    let date = [
        obj.getFullYear(),
        doubleDigit(obj.getMonth() + 1),
        doubleDigit(obj.getDate()),
    ]

    let time = [
        doubleDigit(obj.getHours()),
        doubleDigit(obj.getMinutes()),
        doubleDigit(obj.getSeconds()),
    ]

    return `${date.join('/')} ${time.join(':')}`;
}

function doubleDigit(number: number): string {
    if (String(number).length === 1) {
        return 0 + String(number);
    } else {
        return String(number);
    }
}
