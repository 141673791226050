export const firebaseConfig = {
    apiKey: "AIzaSyDVKMmbkLOHEPIhiDwKm1bto-VEeqgvjnA",
    authDomain: "vites-tips.firebaseapp.com",
    databaseURL: "https://vites-tips.firebaseio.com",
    projectId: "vites-tips",
    storageBucket: "vites-tips.appspot.com",
    messagingSenderId: "399277953253",
    appId: "1:399277953253:web:c0b067a0012000a99c840c",
    measurementId: "G-JVJ7QRPFPL"
};
