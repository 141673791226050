import * as firebase from "firebase/app";
import {readableDateTime} from "../lib/readabledatetime";

export const flags: {ita: string, eng: string, deu: string} = {
    ita: "https://firebasestorage.googleapis.com/v0/b/vites-tips.appspot.com/o/lang%2Fita.png?alt=media",
    eng: "https://firebasestorage.googleapis.com/v0/b/vites-tips.appspot.com/o/lang%2Feng.png?alt=media",
    deu: "https://firebasestorage.googleapis.com/v0/b/vites-tips.appspot.com/o/lang%2Fdeu.png?alt=media",
}

export function pageCardHTML(doc: firebase.firestore.DocumentSnapshot, data: firebase.firestore.DocumentData): string {
    return `
        <div class="card">
            <div class="card-body">
                <button class="close delete-page-btn" type="button">&times;</button>
                <h3 class="card-title">${data.ita.title}</h3>
                <div class="btn-group">
                    <a class="btn btn-light" href="editor.html?page=${doc.id}&lang=ita">${langImg(flags['ita'])}</a>
                    <a class="btn btn-light" href="editor.html?page=${doc.id}&lang=eng">${langImg(flags['eng'])}</a>
                    <a class="btn btn-light" href="editor.html?page=${doc.id}&lang=deu">${langImg(flags['deu'])}</a>
                </div>
            </div>
            <p class="card-text mb-2">
                <small class="text-muted">Ultima modifica: ${readableDateTime(data.edited.seconds * 1000)}</small><br>
                <small class="text-muted">Eseguita da: ${data.editedBy || 'sconosciuto'}</small><br>
                <small class="text-muted">ID: <span class="page-id">${doc.id}</span> <i class="copy-id-btn fas fa-copy"></i></small>
            </p>
        </div>
        `
}

function langImg(url) {
    return `
<img src="${url}" alt="auto-img" class="inline-img">
`
}
