import * as bootbox from "bootbox";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import {pageCardHTML} from "./elements/elements";
import {firebaseConfig} from "./lib/firebase-config";
import {signInPrompt} from "./lib/sign-in-prompt";
import {newPagePrompt} from "./lib/new-page-prompt";
import {deletePagePrompt} from "./lib/delete-page-prompt";

const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();
export const auth = app.auth();

interface CustomClickEvent extends MouseEvent {
    currentTarget: HTMLElement
}


export function loadComponents() {
    showNewPageBtn();
    showPages();
    document.getElementById('logout').addEventListener('click', () => {
        auth.signOut().then(() => {
            window.location.reload();
        })
    })
}

function showPages() {
    let pagesDiv = document.getElementById('pages');
    pagesDiv.innerHTML = '';

    db.collection('pages').orderBy('edited', 'desc').get()
        .then((collection: any) => {
            collection.docs.forEach((doc: firebase.firestore.DocumentSnapshot) => {
                let data: firebase.firestore.DocumentData = doc.data();
                pagesDiv.innerHTML += pageCardHTML(doc, data);
            });

            const pageCards = document.getElementsByClassName('copy-id-btn');
            for (let i = 0; i < pageCards.length; i++) {
                pageCards[i].addEventListener('click', (event: CustomClickEvent) => {
                    let pageID: string = event.currentTarget.parentElement.querySelector('.page-id').innerHTML;
                    navigator.clipboard.writeText('#' + pageID + ':{{cl}}').then();
                });
            }

            const deleteButtons = document.getElementsByClassName('delete-page-btn');
            for (let i = 0; i < deleteButtons.length; i++) {
                deleteButtons[i].addEventListener('click', (event: CustomClickEvent) => {
                    let pageID: string = event.currentTarget.parentElement.parentElement.querySelector('.page-id').innerHTML;
                    deletePagePrompt(db, pageID);
                });
            }
        })
        .catch((error: firebase.firestore.FirestoreError) => {
            pagesDiv.innerHTML = '';
            console.error(error);
            bootbox.alert({
                title: 'Si è verificato un errore',
                message: `
                    <div class="text-center">
                        <h5 class="text-danger">${error.code}</h5>
                        <p>${error.message}</p>
                        <p>${error.stack}</p>
                    </div>
                `,
                animated: true,
                centerVertical: true,
                buttons: {
                    ok: {
                        label: 'Ok',
                        className: 'btn-secondary',
                        callback: function() {},
                    },
                }
            });
        })
}

function showNewPageBtn() {
    const newPageSpinner = document.getElementById('new-page-spinner');
    if (newPageSpinner)
        newPageSpinner.remove();

    const newPageDiv = document.getElementById('new-page');
    newPageDiv.hidden = false;
    newPageDiv.addEventListener('click', newPagePrompt);
}

auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then();

auth.onAuthStateChanged((user: any) => {
    if (user) {
        loadComponents();
        document.getElementById('username').innerText = user.email;
    } else {
        signInPrompt(auth);
    }
});
