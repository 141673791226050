require('bootstrap');
import * as firebase from "firebase/app";
import * as bootbox from "bootbox";

export function signInPrompt(auth: firebase.auth.Auth) {
    return bootbox.dialog({
        title: "Esegui l'accesso",
        message: `
            <form action="" class="formName">
            <div class="form-group">
            <input type="email" placeholder="Email" class="auth-email form-control" />
            <span class="text-danger mt-3 mb-3" id="form-email-error" hidden>Email non valida</span>
            <br>
            <input type="password" placeholder="Password" class="auth-pwd form-control" />
            <span class="text-danger mt-3 mb-3" id="form-pwd-error" hidden>Password non valida</span>
            </div>
            </form>
        `,
        closeButton: false,
        centerVertical: true,
        animate: true,
        buttons: {
            signIn: {
                label: 'Accedi',
                className: 'btn-success',
                callback: function() {
                    let email: string = this.find('.auth-email').val();
                    let pwd: string = this.find('.auth-pwd').val();
                    let emailError: any = document.getElementById('form-email-error');
                    let pwdError: any = document.getElementById('form-pwd-error');
                    if (email === '') {
                        emailError.hidden = false;
                        return false;
                    } else {
                        emailError.hidden = true;
                    }
                    if (pwd.match(/.+/)) {
                        pwdError.hidden = true;
                    } else {
                        pwdError.hidden = false;
                        return false;
                    }
                    auth.signInWithEmailAndPassword(email, pwd)
                        .then(() => {
                            // window.location.reload();
                        })
                        .catch((error: firebase.auth.Error) => {
                            console.error(error);
                            bootbox.alert({
                                title: 'Si è verificato un errore',
                                message: `
                                    <div class="text-center">
                                        <h5 class="text-danger">${error.code}</h5>
                                        <p>${error.message}</p>
                                    </div>
                                `,
                                centerVertical: true,
                                buttons: {
                                    ok: {
                                        label: 'Ok',
                                        className: 'btn-secondary',
                                        callback: function() {
                                            window.location.reload();
                                        },
                                    },
                                }
                            })
                        });
                }
            },
        }
    });
}
