import {loadComponents} from "../index";
import * as firebase from "firebase/app";
import * as bootbox from "bootbox";

require('bootstrap');

export function deletePagePrompt(db: firebase.firestore.Firestore, pageID: string) {
    return bootbox.dialog({
        title: "Sei sicuro?",
        message: "La pagina sarà eliminata definitivamente?",
        animate: true,
        centerVertical: true,
        buttons: {
            confirm: {
                label: 'Conferma',
                className: 'btn-primary',
                callback: function() {
                    db.collection('pages').doc(pageID).delete()
                        .then(() => {
                            loadComponents();
                            /**
                             * TODO: Should this be allowed?
                             */
                            // db.collection('pagesHTML').doc(pageID).delete().then(() => {
                            //
                            // });
                        });
                },
            },
            cancel: {
                label: 'Annulla',
                className: 'btn-secondary',
                callback: function() {},
            },
        }
    });
}
