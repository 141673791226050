import {auth, db, loadComponents} from "../index";
import * as firebase from "firebase/app";
import * as bootbox from "bootbox";

require('bootstrap');

export function newPagePrompt() {
    bootbox.dialog({
        title: "Nuova pagina",
        message: `
            <form action="" class="formName">
            <div class="form-group">
            <input type="text" placeholder="Titolo ITA" class="new-page-title-ita form-control" />
            <span class="text-danger mt-3 mb-3" id="form-title-ita-error" hidden>Titolo non valido</span>
            <br>
            <input type="text" placeholder="Titolo ENG" class="new-page-title-eng form-control" />
            <span class="text-danger mt-3 mb-3" id="form-title-eng-error" hidden>Titolo non valido</span>
            <br>
            <input type="text" placeholder="Titolo DEU" class="new-page-title-deu form-control" />
            <span class="text-danger mt-3 mb-3" id="form-title-deu-error" hidden>Titolo non valido</span>
            </div>
            </form>
        `,
        onEscape: true,
        backdrop: true,
        centerVertical: true,
        animate: true,
        buttons: {
            confirm: {
                label: 'Conferma',
                className: 'btn-success',
                callback: function() {
                    let anyErrors = false;
                    let titleIta: string = this.find('.new-page-title-ita').val();
                    let titleItaError: any = document.getElementById('form-title-ita-error');
                    if (titleIta === '') {
                        titleItaError.hidden = false;
                        anyErrors = true;
                    } else {
                        titleItaError.hidden = true;
                    }

                    let titleEng: string = this.find('.new-page-title-eng').val();
                    let titleEngError: any = document.getElementById('form-title-eng-error');
                    if (titleEng === '') {
                        titleEngError.hidden = false;
                        anyErrors = true;
                    } else {
                        titleEngError.hidden = true;
                    }

                    let titleDeu: string = this.find('.new-page-title-deu').val();
                    let titleDeuError: any = document.getElementById('form-title-deu-error');
                    if (titleDeu === '') {
                        titleDeuError.hidden = false;
                        anyErrors = true;
                    } else {
                        titleDeuError.hidden = true;
                    }

                    if (anyErrors)
                        return false;

                    db.collection('pages').add({
                        ita: {
                            title: titleIta,
                            body: '{}',
                        },
                        eng: {
                            title: titleEng,
                            body: '{}',
                        },
                        deu: {
                            title: titleEng,
                            body: '{}',
                        },
                        edited: firebase.firestore.FieldValue.serverTimestamp(),
                        editedBy: auth.currentUser.email,
                    })
                        .then(() => {
                            loadComponents();
                        })
                        .catch((error: firebase.firestore.FirestoreError) => {
                            console.error(error);
                            bootbox.alert({
                                title: 'Si è verificato un errore',
                                message: `
                                    <div class="text-center">
                                        <h5 class="text-danger">${error.code}</h5>
                                        <p>${error.message}</p>
                                    </div>
                                `,
                                centerVertical: true,
                                buttons: {
                                    ok: {
                                        label: 'Ok',
                                        className: 'btn-secondary',
                                        callback: function() {
                                            window.location.reload();
                                        },
                                    },
                                }
                            })
                        });
                }
            },
            cancel: {
                label: 'Annulla',
                className: 'btn-secondary',
                callback: function() {}
            },
        }
    });
}
